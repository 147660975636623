import React from 'react'
import { graphql } from 'gatsby'

// Components
// import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
// import CTABanner from 'components/Common/CTABanner'
import ProtoContent from 'components/Diensten/ProtoContent'
// import Content from 'components/Content'
import HuisOpgeven from 'components/Diensten/HuisOpgeven'
import Filter from 'components/Home/Filter'

// Third Party
import styled from 'styled-components'

const Aanbod = styled.div``

const Title = styled.h2`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 3rem;

  @media (max-width: 991px) {
    font-size: 25px;
  }
`

const Default = ({ data: { page }, pageContext }) => (
  <Layout>
    {/* {() => ( */}
      <>
        {/* <SEO yoast={{ meta: page.yoast_meta }} /> */}
        <Aanbod>
          <CustomBreadCrumb
            data={page}
            className="py-2"
            pageContext={pageContext}
          />
          <div className="container">
            <div className="row py-5">
              {page.title === 'Aanbod' ? (
                <>
                  <Title className="text-center">{page.title}</Title>
                  <Filter />
                </>
              ) : (
                <>
                  <div className="col-12 col-lg-7 pl-lg-3">
                    <ProtoContent
                      content={page.templateDefault.inhoudDefault.content}
                      title={page.title}
                    />
                    {/* <Content content={page.templateDefault.inhoudDefault.content} /> */}
                  </div>
                  <div className="col-12 col-lg-5 pt-5 pt-lg-0 pr-lg-3">
                    <HuisOpgeven className="pb-5 pt-4 pt-lg-0 justify-content-center justify-content-lg-end" />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* <CTABanner data={page} /> */}
        </Aanbod>
      </>
    {/* )} */}
  </Layout>
)

export default Default

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      uri
      templateDefault {
        bannerDefault {
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
        }
        inhoudDefault {
          content
          link {
            target
            title
            url
          }
        }
      }
      # yoast_meta {
      #   name
      #   content
      #   property
      # }
    }
  }
`
